import React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'
import { Link, useStaticQuery, graphql } from 'gatsby'
import useSiteMetadata from '../components/SiteMetadata'

const NotFoundPage = () => {
  const { title } = useSiteMetadata()
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {in: ["error.jpg"]}) {
        childImageSharp {
          fluid(maxWidth: 1536, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `
  );

  return (
    <Layout>
      <Helmet>
        <title>{`404 | ${title}`}</title>
      </Helmet>
        <section
          className="hero pt-176 md:pt-416 bg-no-repeat bg-top bg-black relative overflow-hidden"
        >
          <Img 
            fluid={ data.file.childImageSharp.fluid } className="hero__image" 
            objectFit="cover" 
            objectPosition="50% 50%" 
            style={{position: "absolute"}}
          />

          <section className="hero__overlay mb-528">
            <div className="container flex flex-wrap flex-col space-y-32 text-white text-center py-24">
              <h3 className="text-12 md:text-16 text-white font-serif uppercase tracking-wider">
                The exterior of normality
              </h3>
              <h1 className="text-white font-serif uppercase">
                Page not found
              </h1>
              <div>
                <Link 
                  to="/" 
                  className="inline-block py-8 px-40 lg:py-16 lg:px-64 rounded bg-red-200"
                >
                  Return to safety...
                </Link>
              </div>
            </div>
          </section>
          <div className=" min-h-528"></div>
        </section>
    </Layout>
  )
}

export default NotFoundPage
